@import "../../index.scss";

.nav-container {
    background: rgba(29, 29, 31, 0.72);
    backdrop-filter: saturate(1.8) blur(20px);
    -webkit-backdrop-filter: saturate(1.8) blur(20px);
    height: 48px;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--border-color);
}

.nav-container nav button {
    text-align: left;
    font-family: var(--text-font);
}

.nav-container nav {
    width: 1000px;
    margin: 0 auto;
    padding: 0 8px;
    height: 100%;
}

nav .desktop-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

nav .desktop-nav li button{
    background-color: transparent;
    color: var(--primary-txt);
    text-decoration: none;
    font-size: 14px;
    opacity: 0.8;
    border: none;
    transition: opacity 400ms;
}

nav .desktop-nav li a{
    background-color: transparent;
    color: var(--primary-txt);
    text-decoration: none;
    font-size: 14px;
    opacity: 0.8;
    border: none;
    transition: opacity 400ms;
}
nav .desktop-nav li a:hover{
    opacity: 1;
    cursor: pointer;
}
nav .desktop-nav li button:hover{
    opacity: 1;
    cursor: pointer;
}

.link-logo {
    background: url("../../assets/images/abdallah-logo.svg");
    display: block;
    background-position: center;
    height: 44px;
    background-size: 18px;
    width: 20px;
    background-repeat: no-repeat;
}

.mobile-nav {
    display: none;
}

@media (max-width: 1100px) {
    .nav-container nav {
        width: 100%;
        padding: 0 32px;
    }
}

@media (max-width: 768px) {
    .nav-container .desktop-nav {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0vh;
        background: rgb(0, 0, 0);
        justify-content: start;
        overflow: hidden;
        z-index: -1;
        transition: all 1000ms ease;
    }

    .nav-container.active .desktop-nav {
        height: 100vh;
    }

    .nav-container .desktop-nav li {
        width: 100%;
        padding: 0 32px;
    }

    .nav-container .desktop-nav li:first-child {
        margin-top: 120px;
    }

    .nav-container .desktop-nav .link-logo {
        display: none;
    }

    .nav-container .desktop-nav li button{
        background-color: transparent;
        border: none;
        padding: 16px 16px;
        display: inline-block;
        border-bottom: 1px solid #616161;
        width: 100%;
        font-size: 17px;
        transform: translateY(-80px);
        opacity: 0;
        transition: all 700ms ease;
    }

    .nav-container .desktop-nav li a{
        background-color: transparent;
        border: none;
        padding: 16px 16px;
        display: inline-block;
        border-bottom: 1px solid #616161;
        width: 100%;
        font-size: 17px;
        transform: translateY(-80px);
        opacity: 0;
        transition: all 700ms ease;
    }

    .nav-container.active .desktop-nav li  button{
        transform: translateY(0);
        opacity: 1;
    }
    .nav-container.active .desktop-nav li  a{
        transform: translateY(0);
        opacity: 1;
    }

    nav .mobile-nav {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        list-style: none;
    }

    nav .mobile-nav li  button {
        border: none;
    }

    nav .menu-icon-container {
        width: 20px;
        height: 44px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    nav .menu-icon {
        position: relative;
        width: 100%;
    }

    nav .menu-icon .line-1,
    nav .menu-icon .line-2 {
        position: absolute;
        height: 1px;
        width: 100%;
        background: rgb(255, 255, 255);
        transition-property: transform, top;
        transition-delay: 0ms, 160ms;
        transition-duration: 200ms;
    }

    nav .menu-icon .line-1 {
        top: -4px;
    }

    nav .menu-icon .line-2 {
        top: 4px;
    }

    .nav-container.active nav .menu-icon-container .menu-icon .line-1 {
        top: 0;
        transform: rotateZ(45deg);
        transition-property: top, transform;
        transition-delay: 0ms, 160ms;
        transition-duration: 200ms;
    }

    .nav-container.active nav .menu-icon-container .menu-icon .line-2 {
        top: 0;
        transform: rotateZ(-45deg);
        transition-property: top, transform;
        transition-delay: 0ms, 160ms;
        transition-duration: 200ms;
    }
}

input,
div,
span,

button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
