/* Fonts */

@font-face {
    font-family: 'SFProDisplay-Medium';
    src: local('SFProDisplay-Medium'),
    url('./assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProText-Regular';
    src: local('SFProText-Regular'),
    url('./assets/fonts/SFProText-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SFProText-Light';
    src: local('SFProText-Light'),
    url('./assets/fonts/SFProText-Light.ttf') format('truetype');
}


/* Main Styles */

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    z-index: auto;
}

:root {
    --bg-color: #000000;
    --page-color: #101010;
    --card-color: #141414;
    --navbar-color: #1d1d1f;
    --border-color: #48484a;
    --tertiary-txt: #636366;
    --secondary-txt: #aeaeb2;
    --primary-txt: #f5f5f7;
    --accent-color: #0072e3;
    --accent-highlight: #3d93ea;
    --cisco-color: #0c8cdd;
    --title-font: 'SFProDisplay-Medium';
    --subtitle-font: 'SFProText-Regular';
    --text-font: 'SFProText-Light';
    --title-size: 64px;
    --header-size: 40px;
    --subtitle-size: 28px;
    --text-size: 15px;
    overflow-x: hidden;
    scroll-behavior: smooth;
    list-style-position: inside;
}

body {
    background-color: var(--bg-color);
    color: var(--primary-txt);
    font-family: var(--text-font);
    font-size: var(--text-size);
}

a, a:hover{
    text-decoration: none;
    color: inherit;
}

a:hover {
    cursor: pointer;
}
button {
    background: transparent;
    border: none;
    text-decoration: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
}

section {
    scroll-snap-align: start;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: stretch;
}

.link {
    color: var(--accent-color);
}

.link:hover {
    color: var(--accent-color);
    filter: brightness(120%);
    cursor: pointer;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--accent-color);
    color: var(--primary-txt);
    font-family: var(--title-font);
    font-size: var(--text-size);
    padding: 10px 40px;
    border: none;
    border-radius: 40px;
    max-width: 33vw;
    max-height: 48px;
    transition: all 400ms ease;
}

.btn:hover {
    background-color: var(--accent-color);
    filter: brightness(120%);
    cursor: pointer;
}

.btn-group{
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-color: var(--card-color);
    border-radius: 14px;
    margin: 0px 20px 20px 0;
    width: calc(50% - 20px);
    min-width: 320px;
    min-height: 250px;
    padding: 25px;
}

.card-group {
    display: flex;
    flex-wrap: wrap;
    min-width: 680px;
    margin: 0;
    justify-content: space-between;
}


/* Media Queries*/

@media only screen and (max-width: 906px) {
    .card-group {
        min-width: 0;
        width: 100%;
    }
    .card-group .card {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        max-width: none;
        min-width: 0;
    }
}


/* AOS Library Animations */

[data-aos ='sm-fade-up'] {
    opacity: 0;
    transform: translateY(30px);
    transition-property: transform, opacity;
    &.aos-animate {
        opacity: 1;
        transform: translateY(0px);
    }
}

[data-aos = 'sm-fade-down'] {
    opacity: 0;
    transform: translateY(-20px);
    transition-property: transform, opacity;
    &.aos-animate {
        opacity: 1;
        transform: translateY(0px);
    }
}

[data-aos = 'fade'] {
    opacity: 0;
    transition-property: opacity;
    transition-delay: 200ms;

    &.aos-animate {
        opacity: 1;
    }
}

[data-aos = 'fade-long'] {
    opacity: 0;
    transition-property: opacity;
    transition-delay: 500ms;

    &.aos-animate {
        opacity: 1;
    }
}