@import '../../index.scss';


.os_page {
    background-color: var(--bg-color);
    width: 100vw;
    min-height: 100vh;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.os_page .main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 55vw;
    min-width: 350px;
    max-width: 1920px;
}

.os_page .main .title {
    font-family: var(--title-font);
    font-size: var(--title-size);
    padding: 10px 0 25px 0;
    display: flex;
    flex-wrap: wrap;
}

.os_page .colorful {
    background:white;
    background: -webkit-linear-gradient(to bottom left, #D4E34D 0%, #E81E1E 100%);
    background: -moz-linear-gradient(to bottom left, #D4E34D 0%, #E81E1E 100%);
    background: linear-gradient(to bottom left, #D4E34D 0%, #E81E1E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--title-font);
    font-size: var(--title-size);
    

}

.os_page .main .subtitle {
    font-family: var(--title-font);
    font-size: var(--subtitle-size);
    margin: 25px 0 10px 0;
}

.os_page .main .paragraph {
    width: 100%;
    line-height: 1.4;
}

.os_page .main .paragraph .code {
    font-family:'Courier New', Courier, monospace;
    display: inline;
    color: rgb(255, 76, 255);
    
}

.os_page .main .paragraph .bold {
    font-weight: bold;
    display: inline;
    color: rgb(255, 76, 255);
    color: rgb(99, 195, 215);
}

.os_page .main .paragraph .param {
    font-family:'Courier New', Courier, monospace;
    display: inline;
    color: white;
    
}



.os_page .main .image {
    background-image: url('../../assets/images/laptop.jpg');
    background-position: center;
    background-repeat: no-repeat;
    // background-color: red;
    background-size: 120%;
    
    width: 100%;
    height: 400px;
    border-radius: 14px;
    margin: 25px auto;
    margin-top: 50px;
    min-width: 300px;
}

.os_page .main .btn-group {
    display: flex;
    justify-content: center ;
}

.os_page .main .btn {
    font-family: var(--text-font);
    margin: 25px 10px;
    width: 100px;
    padding: 3px;
}

.os_page .main .fas {
    font-size: 12px;
    padding: 5px;
}

@media only screen and (max-width: 1236px) {
    .os_page .main {
        width: 70vw;
    }
    .os_page .main .image {
        width: 70vw;
        background-size: 100%;
    }
}


@media only screen and (max-width: 910px) {
   
    .os_page .main .image {
        width: 100%;
        background-size: 140%;
    }
}

