@import '../../index.scss';

.landing_page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: stretch;
    padding-top: 48px;
    overflow: hidden;
}

.landing_page .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 44px;
    background-color: var(--accent-color);
    border-top: 1px solid var(--accent-highlight);
    transform-origin: top;
    animation: SlideReveal 700ms ease 100ms 1 forwards;
}

.landing_page .banner .banner-text {
    font-size: 14px;
    text-align: center;
}

.landing_page .banner .banner-text:hover{
    text-decoration: underline;
}

.landing_page .banner .icon {
    padding: 5px;
}

.landing_page .main{
    width: 100vw;
    height: calc(100vh - (44px + 44px));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing_page .main .title{
    font-family: var(--title-font);
    font-size: var(--title-size);
    text-align: center;

    background:white;
    background: -webkit-linear-gradient(45deg,#ff9fe1,#9d44fd 50%,#8727ff);
    background: -moz-linear-gradient(45deg,#ff9fe1,#9d44fd 50%,#8727ff);
    background: linear-gradient(45deg,#ff9fe1,#9d44fd 50%,#8727ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--title-font);
    font-size: var(--title-size);
    
}

.landing_page .main .subtitle {
    font-family: var(--subtitle-font);
    font-size: var(--subtitle-size);
    text-align: center;
    font-family: var(--subtitle-font);
    font-size: var(--subtitle-size);
    text-align: center;
}

.landing_page .main .subtitle .cisco,
.landing_page .main .subtitle .symbol 
{
    display: inline;
    color: var(--cisco-color);
}

.landing_page .main .subtitle .cisco:hover{
    text-decoration: underline;
    transition: all ease-in 500ms;
}

.landing_page .main .description {
    font-family: var(--text-font);
    font-size: 24px;
    text-align: center;
    padding: 10px;
}

.landing_page .main .image{
    background-image: url('../../assets/images/pinkButterfly.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90vh; //100vh
    width: 70vh;
    height: 40vh;
    max-width: 3840px;
    max-height: 2160px;

}

.landing_page .main .description.animate__fadeIn {
    animation-delay: 700ms;
}

.landing_page .main .image.animate__fadeIn {
    animation-delay: 700ms;
}

.landing_page .main .btn{
    margin: 20px;
    font-family: var(--text-font);
}

.landing_page .main .btn-group.animate__fadeIn {
    animation-delay: 700ms;
}

@keyframes SlideReveal {
    0% {
        transform: scaleY(0%);
        opacity: 0%;
        visibility: hidden;
    }
    100% {
        transform: scaleY(100%);
        opacity: 100%;
        visibility: visible;
    }
}

.landing_page .main .link {
    font-size: 17px;
    margin: 10px;
}

.landing_page .main .link .fas {
    font-size: 15px;
    margin: 5px;
}

.landing_page .main .link:hover{
    text-decoration: underline;
}