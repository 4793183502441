@import '../../index.scss';

.about_page {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: 48px;
    align-items: center;
}

.about_page .main {
    height: 100%;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;

}

.about_page .main .paragraph {
    max-width: 550px;
}

.about_page .main .title {
    font-family: var(--subtitle-font);
    font-size: var(--header-size);
    text-align: left;
}

.about_page .main .paragraph .paragraph-text {
    line-height: 1.4;
}

.about_page .main .image {
    background-image: url('../../assets/images/face.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 300px;
    width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 20px;
    border-radius: 13px;
}


@media only screen and (max-width: 1236px) {
    .about_page .main {
        width: 90vw;
    }
}
