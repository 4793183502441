@import '../../index.scss';

.projects_page{
    width: 100vw;
    min-height: 100vh;
    min-height: 100vh;
    padding-top: 48px;
    display: flex;
    align-items: center;
    background-color: var(--navbar-color);
}

.projects_page .main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 70vw;
    min-width: 350px;
    max-width: 1920px;
}
.projects_page .main .card-group .card .card-info{
    height: 85%;
}

.projects_page .main .title {
    font-family: var(--subtitle-font);
    font-size: var(--header-size);
    padding: 20px 0;
    margin-top: 45px;
    letter-spacing: -1px;
}

.projects_page .main .card-title {
    font-family: var(--subtitle-font);
    font-size: var(--subtitle-size);
    margin-bottom: 15px;
    letter-spacing: -2px;
}
.projects_page .main .description {
    font-family: var(--text-font);
    font-size: var(--text-size);
    line-height: 1.4;
}

.projects_page .main .link {
    padding: 20px 0 0 0;
    // text-align: right;
    transition: all 600ms ease;
}

.projects_page .main .link:hover{
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.projects_page .main .link .fas {
    font-size: 12px;
    margin: 5px;
}



@media only screen and (max-width: 1236px) {
    .projects_page .main {
        width: 90vw;
    }
}
