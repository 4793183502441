@import '../../index.scss';

.contact_page{
    background-color: var(--navbar-color);
    width: 100vw;
    min-height: 40vh;
    padding: 10px;
    display: flex;
    align-items: center;
}

.contact_page .main {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    width: 70vw;
}

.contact_page .main .title {
    font-family: var(--subtitle-font);
    font-size: var(--header-size);
    margin: 15px 0;
}

.contact_page .main .contact_container .subtitle{
    font-family: var(--subtitle-font);
    font-size: var(--subtitle-size);
    padding: 10px 0;
}


.contact_page .main .contact_container{
    display: flex;
    flex-wrap: wrap;
}

.contact_page .main .contact_container .social_container {
    width: 30%;
    
}

.contact_page .main .contact_container .social_container .socials {
    list-style-type: none;
}

.contact_page .main .contact_container .social_container .socials li{
    margin: 5px;
}
.contact_page .main .contact_container .social_container .socials li .fab,
.contact_page .main .contact_container .social_container .socials li .fas {
    width: 16px;
}

.contact_page .main .contact_container .about_container .text {
    color: var(--tertiary-txt);
}