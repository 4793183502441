@import '../../index.scss';


.dm_page {
    background-color: var(--bg-color);
    width: 100vw;
    min-height: 100vh;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
}

.dm_page .colorful {
    background:white;
    background: -webkit-linear-gradient(45deg,#30d158,#21963e 50%,#03701e);
    background: -moz-linear-gradient(45deg,#30d158,#21963e 50%,#03701e);
    background: linear-gradient(45deg,#30d158,#21963e 50%,#03701e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--title-font);
    font-size: var(--title-size);
}

.dm_page .main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 55vw;
    min-width: 350px;
    max-width: 1920px;
}

.dm_page .main .title {
    font-family: var(--title-font);
    font-size: var(--title-size);
    padding: 10px 0 25px 0;
    display: flex;
    flex-wrap: wrap;
}

.dm_page .main .subtitle {
    font-family: var(--title-font);
    font-size: var(--subtitle-size);
    margin: 25px 0 10px 0;
}

.dm_page .main .paragraph {
    width: 90%;
    line-height: 1.4;
}

.dm_page .main .image {
    background-image: url('../../assets/images/fractal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 300px;
    width: 300px;
    height: 300px;
    border-radius: 14px;
    margin: 20px auto;
    margin-top: 50px;
}

.dm_page .main .btn-group {
    display: flex;
    justify-content: center ;
}

.dm_page .main .btn {
    font-family: var(--text-font);
    margin: 25px 10px;
    width: 100px;
    padding: 3px;
}

.dm_page .main .fas {
    font-size: 12px;
    padding: 5px;
}

@media only screen and (max-width: 1236px) {
    .dm_page .main {
        width: 70vw;
    }
}